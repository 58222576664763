<template>
  <div id="map">
    <!--  :mapStyle="mapStyle" -->
    <baidu-map class="map" :zoom="zoom" :center="{lng: longitude, lat: latitude}" :scroll-wheel-zoom="true">
      <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation> -->
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" type="BMAP_NAVIGATION_CONTROL_PAN" :offset="{width: 35,height:10}"></bm-navigation>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" type="BMAP_NAVIGATION_CONTROL_LARGE" :offset="{width: 10,height:60}"></bm-navigation>
      <bml-marker-clusterer :averageCenter="true">
        <bm-marker :position="{ lng: longitude, lat: latitude }" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
      </bml-marker-clusterer>
      <bm-control>
      </bm-control>
    </baidu-map>
  </div>
</template>
<script>

import { BmlMarkerClusterer } from "vue-baidu-map";

import styleJson from '../../../utils/styleJson'

export default {
  props: ['longitude', 'latitude'],
  components: {
    BmlMarkerClusterer,
  },
  data() {
    return {
      mapStyle: styleJson.styleJson,
      zoom: 16,
      deviceType: [],
      deviceTypeChecked: "",
      // 坐标点
      markersOfDevice: [],
      // 坐标对应的设备 id
      deviceId: "",
      typeId: "",

      // 地图风格
      // mapStyle: styleJson,

      // 静态属性
      staticAttributes: [],
      // 动态属性
      dynamicProperties: [],
    };
  },
  created() {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 51vh;
}

/deep/.anchorBL {
  display: none;
}
</style>