<template>
  <div id="app">
    <!-- <el-row style="margin-top:10px">
      <el-col :span="24">
        <el-page-header @back="goSelect()" content="一键报警"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-header class="header-bg bgclor" style="height: 80px;width:100%;position:absolute;top:0px">
      <show-header></show-header>
    </el-header>

    <div class="container">
      <!-- <div class="table"> -->
      <dv-border-box-7 class="table" :color="['#07e4e4', '#07e4e4']">
        <div style="height: 91%">
          <el-table :data="AKeyAlarmInfoList" height="100%" @row-click="showInfo" style="cursor: pointer" :highlight-current-row="true">
            <el-table-column prop="name" label="姓名" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.wechatUserInfoName!=''&&scope.row.wechatUserInfoName!=undefined&&scope.row.wechatUserInfoName!=null">{{ scope.row.wechatUserInfoName }}</span>
                <span v-else>未知</span>
              </template>
            </el-table-column>

            <el-table-column prop="name" label="报警类型" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="处理状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="scope.row.dealWith == '已处理' ? 'success' : 'danger'">
                  <span>{{ scope.row.dealWith }}</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="报警时间" align="center" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination :pager-count="5" :current-page.sync="pageAKeyAlarm.current" :page-size="pageAKeyAlarm.limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="getPageAKeyAlarm" class="pagination"></el-pagination>
        </div>
      </dv-border-box-7>

      <div class="content">
        <div class="content1">
          <!-- 信息描述 -->
          <!-- <dv-border-box-1>dv-border-box-1</dv-border-box-1> -->
          <dv-border-box-7 class="describe" :color="['#07e4e4', '#07e4e4']">
            <!-- 描述信息 -->
            <h1>用户信息:</h1>
            <dv-decoration-3 style="width:200px;height:15px;margin-bottom:5px" />
            <div class="describe-box">
              <div class="box">
                <div class="item" :title="activeInfo.wechatUserInfoName"><span>用户名：</span>{{activeInfo.wechatUserInfoName}}</div>
                <div class="item" :title="activeInfo.phone"><span>手机号：</span>{{activeInfo.phone}}</div>
                <div class="item" :title="activeInfo.departmentName"><span>所属部门：</span>{{activeInfo.departmentName}}</div>
                <div class="item" :title="activeInfo.isHandOut"><span>是否分派：</span>{{activeInfo.isHandOut}}</div>
                <div class="item" :title="activeInfo.completeNote"><span>处理说明：</span>{{activeInfo.completeNote}}</div>
                <div class="item" :title="activeInfo.reportNote"><span>上报描述：</span>{{activeInfo.reportNote==null?'无':activeInfo.reportNote}}</div>
              </div>
              <div class="item1"><span>上报地点：</span>{{activeInfo.location}}</div>

            </div>

            <div class="btns">
              <el-button @click="showPersonHistory" type="info" plain>查看此人报警历史</el-button>
              <el-button @click="handOut" type="success" plain v-if="userRightList.updateJuris=='1'">分 派</el-button>
              <el-button type="primary" plain v-if="userRightList.updateJuris=='1'" @click="function(){SolveDialog=true;solveInfo=''}">处 理</el-button>
            </div>
          </dv-border-box-7>
          <!-- 视频 -->
          <dv-border-box-7 class="video" :color="['#07e4e4', '#07e4e4']">
            <div class="video-box" v-if="activeInfo.type=='治安报警'">
              <div style="text-align:center;line-height:300px;user-select:none;font-size:18px;color:#999">暂无图片</div>
            </div>
            <div class="video-box" v-else-if="activeInfo.type=='安全隐患'|| activeInfo.type=='故障报修'">
              <el-carousel :interval="3000" arrow="always">
                <el-carousel-item v-for="(item,index) in activeInfo.imgUrlList" :key="index">
                  <div style="width:100%;height:100%;box-sizing:border-box;">
                    <el-image class="img" :src="item" style="display:inline" fit="scale-down" :preview-src-list="activeInfo.imgUrlList">
                    </el-image>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="video-box" v-else>
              <video height="100%" controls="controls" autoplay :src="videoUrl"></video>
            </div>
          </dv-border-box-7>
        </div>
        <dv-border-box-7 class="content2" :color="['#07e4e4', '#07e4e4']">
          <maps :longitude="activeInfo.longitude" :latitude="activeInfo.latitude"></maps>
        </dv-border-box-7>
      </div>
    </div>

    <!-- 查看人员报警历史记录 -->
    <div>
      <el-dialog :title="personHistory.personalHistoryName + ' 的报警历史记录'" :visible.sync="personHistoryDialog" width="40%" style="background:rgba(0,0,0,0.6);">
        <div class="tablePerson">
          <div style="width:100%;text-align:center;cursor:pointer;height:100%;box-sizing:border-box">
            <div style="height:15%">
              <span style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:18px;font-weight:600">报警类型</span>
              <span style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:18px;font-weight:600">处理状态</span>
              <span style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:18px;font-weight:600">报警时间</span>
            </div>
            <div style="width:100%;height:85%;overflow:scroll">
              <div v-for="item in personHistory.AKeyAlarmPersonHistory">
                <span style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:16px;">{{item.type}}</span>
                <span :style="item.dealWith.indexOf('未')!=-1?'color:red':'color:green'" style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:16px;">{{item.dealWith}}</span>
                <span style="display:inline-block;width:33.33%;height:40px;line-height:40px;font-size:16px;">{{item.createTime}}</span>
              </div>
            </div>
          </div>
          <div>
            <el-pagination pager-count="5" :current-page.sync="personHistory.pageAKeyAlarm.current" :page-size="personHistory.pageAKeyAlarm.limit" :total="personHistory.total" layout="total, prev, pager, next, jumper" @current-change="getPersonHistory(personHistory.pageAKeyAlarm)" class="pagination"></el-pagination>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="分派任务:" :visible.sync="HandOutDialog" width="20%" style="background:rgba(0,0,0,0.6);">
        <div>
          <el-select v-model="AKeyAlarmPerson.receivePersonPhone" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="item in AKeyAlarmPersonList" :key="item.phone" :label="item.name" :value="item.phone">
            </el-option>
          </el-select>
        </div>
        <div align="right" class="confirm" style="width: 100%">
          <el-button type="primary" @click="confirm" style="margin: 20px 0 0 0; text-align: right">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="处理说明:" :visible.sync="SolveDialog" width="20%" style="background:rgba(0,0,0,0.6);">
      <div>
        <el-input type="textarea" :rows="2" placeholder="请输入处理说明" v-model="solveInfo">
        </el-input>
      </div>
      <div align="right" class="confirm" style="width: 100%">
        <el-button type="primary" @click="dealWith" style="margin: 20px 0 0 0; text-align: right">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ShowHeader from '@/components/deviceClass/ShowHeader'

import aKeyAlarm from "../../api/manageApi/AKeyAlarm";

import AlarmOfMap from "@/components/echarts/AlarmOfMap";

import BaseData from "@/assets/config/BaseData";
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
export default {
  components: {
    maps: AlarmOfMap, ShowHeader
  },
  data() {
    return {
      baseUrl: BaseData.baseUrl,
      wechat: BaseData.wechatName,
      videoUrl: "",

      //所有一键报警信息
      AKeyAlarmInfoList: [],
      pageAKeyAlarm: {
        current: 1, //当前页
        limit: 15, //每页显示条数
        aKeyAlarmQuery: {},
      },
      //数据总数
      total: 0,
      //分派弹窗
      HandOutDialog: false,
      //查看人员报警历史记录
      personHistoryDialog: false,
      //人员信息
      activeInfo: {
        id: "",
        name: "",
        phone: "",
        departmentName: "",
        location: "",
        isHandOut: "",
        completeNote: "",
      },
      //分派
      AKeyAlarmPersonList: [
        {
          name: "",
          phone: "",
        },
      ],
      //分派和处理向后端传的数据
      AKeyAlarmPerson: {
        id: "",
        receivePersonPhone: [],
      },
      status: "",
      //人员报警历史记录
      personHistory: {
        AKeyAlarmPersonHistory: [],
        personalHistoryName: '',
        pageAKeyAlarm: {
          name: '',
          phone: '',
          current: 1, //当前页
          limit: 8, //每页显示条数
        },
        total: 0,
      },
      solveInfo: "",//处理说明
      SolveDialog: false,//处理弹框

      aKeyAlarmId: '',
      //   //右上角展示视频/图片,区分一键报警和二维码报警
      //   showVideo: false,
      //   showImg: false,
      userRightList: [],

    };
  },
  created() {
    this.getUsers()
    //分页查询所有报警记录
    this.getPageAKeyAlarm();
    //查询被分派人员
    this.selectHandOut();

  },

  mounted() {

  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route': function (to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      if (to.query.randomId) {
        this.getPageAKeyAlarm()
      }
    },
  },
  methods: {

    rowStyle({ row, rowIndex }) {
      let stylejson = {}
      if (this.aKeyAlarmId != undefined) {
        if (this.aKeyAlarmId == row.deviceId) {
          stylejson.backgroundColor = '#ffa8a8'
          stylejson.color = '#000'
          return stylejson
        } else {
          return ''
        }
      }
    },

    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "AKeyAlarm") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },


    //返回
    goSelect() {
      this.$router.go(-1);
    },

    getFirst() {
      //开局显示第一条数据
      this.activeInfo = this.AKeyAlarmInfoList[0];
      console.log(this.activeInfo);
      this.changeValue();
    },
    // 分页查询
    async getPageAKeyAlarm() {
      const { data: res } = await aKeyAlarm.getPageAKeyAlarm(this.pageAKeyAlarm)
      this.AKeyAlarmInfoList = res.data.pageAKeyAlarm;
      console.log(this.AKeyAlarmInfoList);
      this.total = res.data.total;
      this.getFirst();

    },
    //查询被分派人员
    selectHandOut() {
      aKeyAlarm.getAllAkeyAlarmPerson().then((response) => {
        this.AKeyAlarmPersonList = response.data.data.AKeyAlarmPerson;
      });
    },
    //点击表格某一行,查看信息
    showInfo(row, column, event) {
      this.activeInfo = row;
      console.log(this.activeInfo);
      this.changeValue();
    },
    //分派任务弹窗
    handOut() {
      //先将分派任务选项清空
      this.AKeyAlarmPerson.receivePersonPhone = [];
      this.HandOutDialog = true;
    },
    //分派任务
    confirm() {
      aKeyAlarm.handOut(this.AKeyAlarmPerson).then((response) => {
        if (response.data.message == "该任务已被分派") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
        if (response.data.message == "该任务已被处理,无法分派") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
        if (response.data.message == "分派成功") {
          this.HandOutDialog = false;
          this.activeInfo.isHandOut = "已分派";
          this.$message({
            type: "success",
            message: response.data.message,
          });
        } else if (response.data.message == "分派失败") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    //处理
    dealWith() {
      if (this.solveInfo == "" || this.solveInfo == null || this.solveInfo == undefined) {
        this.$message({
          type: "error",
          message: "请输入处理说明"
        })
      } else {
        this.AKeyAlarmPerson.completeNote = this.solveInfo;
        aKeyAlarm.dealWith(this.AKeyAlarmPerson).then((response) => {
          if (response.data.message == "处理成功") {
            this.$message({
              type: "success",
              message: response.data.message,
            });
            this.activeInfo.completeNote = this.solveInfo;
            //处理完成之后重新查询页面数据
            this.getPageAKeyAlarm();
          } else if (response.data.message == "该任务不存在") {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          } else if (response.data.message == "已处理") {
            this.$message({
              type: "error",
              message: "该报警信息已被处理",
            });
          }
          this.SolveDialog = false
        });
      }
    },
    // 显示个人历史记录弹框
    showPersonHistory() {
      this.personHistoryDialog = true;
      this.getPersonHistory();
      console.log(this.activeInfo);
    },
    //分页查询此人报警历史记录
    getPersonHistory() {
      //赋值顺序不能变
      if (this.activeInfo.wechatUserInfoName == "" || this.activeInfo.wechatUserInfoName == undefined || this.activeInfo.wechatUserInfoName == null) {
        this.personHistory.personalHistoryName = this.activeInfo.phone;
      } else {
        this.personHistory.personalHistoryName = this.activeInfo.wechatUserInfoName;
      }
      this.personHistory.pageAKeyAlarm.name = this.activeInfo.wechatUserInfoName;
      this.personHistory.pageAKeyAlarm.phone = this.activeInfo.phone;
      aKeyAlarm.getPersonHistory(this.personHistory.pageAKeyAlarm).then((res) => {
        this.personHistory.AKeyAlarmPersonHistory = res.data.data.personHistory.records;
        this.personHistory.total = res.data.data.personHistory.total;
      });
    },
    //封装对数据显示的处理
    async changeValue() {
      //所属单位
      if (this.activeInfo.ciId == "" || this.activeInfo.ciId == null || this.activeInfo.ciId == undefined) {
        this.activeInfo.departmentName = "无"
      } else {
        const { data: response } = await aKeyAlarm.getDepartmentById(this.activeInfo.ciId)
        console.log(response);
        if (response.message == "未绑定部门") {
          this.activeInfo.departmentName = "无"
        } else {
          this.activeInfo.departmentName = response.data.departmentName;
        }
      }
      //上报地点
      this.activeInfo.location == '' || this.activeInfo.location == null || this.activeInfo.location == undefined ? this.activeInfo.location = "无" : this.activeInfo.location
      //是否分派
      this.activeInfo.isHandOut = this.activeInfo.notifyWechatUserInfoId == null ? "未分派" : "已分派";
      //处理描述
      if (this.activeInfo.completeNote == "" || this.activeInfo.completeNote == null || this.activeInfo.completeNote == undefined) {
        this.activeInfo.completeNote = "无"
      } else {
        this.activeInfo.completeNote = this.activeInfo.completeNote;
      }
      //一键报警的视频
      // this.activeInfo.videoUrl = this.wechat + this.activeInfo.videoUrl;
      this.videoUrl = this.wechat + this.activeInfo.videoUrl;
      console.log(this.videoUrl);
      //   //二维码报警 治安报警的图片
      //   if (this.activeInfo.type == "治安报警") {
      //     this.showImg = true;
      //   } else if (this.activeInfo.type == "隐患上报") {
      //     this.showImg = true;
      //   } else {
      //     this.showVideo = false;
      //   }
      //分派时向后端传递的问题id
      this.AKeyAlarmPerson.id = this.activeInfo.id;
    },
  },
};
</script>

<style scoped lang="scss">
.el-carousel__item {
  box-sizing: border-box;
  line-height: 300px;
  margin: 0;
  .img {
    width: 100%;
  }
}

.container {
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 97%;

  .table {
    height: 98%;
    padding: 10px 0;
    width: 25%;
    border-radius: 15px;
    .pagination-box {
      /deep/.el-pagination {
        height: 40px;
      }
      /deep/.el-pagination__total {
        color: #fff;
        font-weight: 600;
      }
      /deep/.el-pagination__jump {
        color: #fff;
        font-weight: 600;
      }
      /deep/.el-pager li {
        background: rgba(1, 79, 85, 0.1);
        color: #fff;
      }
      /deep/.el-pagination .btn-prev {
        background: rgba(1, 79, 85, 0);
        color: #fff;
      }
      /deep/.el-pagination .btn-next {
        background: rgba(1, 79, 85, 0);
        color: #fff;
      }
      /deep/.el-pagination button:disabled {
        background: none;
        color: #fff;
      }
      .pagination {
        width: 100%;
      }
    }
  }
  .content {
    margin-left: 10px;
    box-sizing: border-box;
    width: 75%;
    height: 100%;

    // 用户信息
    .describe {
      position: relative;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      h1 {
        color: rgba(0, 255, 255, 0.952);
        font-size: 20px;
        margin: 5px 0;
        font-weight: 600;
      }
      .describe-box {
        width: 100%;
        height: 65%;
        overflow: scroll;
        scrollbar-width: none;
        color: #0bd9e0;
        font-size: 18px;

        .box {
          display: flex;
          flex-wrap: wrap;
          .item {
            cursor: pointer;
            width: 50%;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              display: inline-block;
              width: 100px;
            }
          }
        }
        .item1 {
          height: 40px;
          line-height: 40px;
          span {
            display: inline-block;
            width: 100px;
          }
        }
      }
      .btns {
        position: absolute;
        bottom: 10px;
        text-align: right;
      }
    }
    .video {
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      box-sizing: border-box;
      margin-left: 10px;

      padding: 10px;
      overflow: hidden;

      .video-box {
        width: 100%;
        height: 100%;
        video {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
    .content1 {
      width: 100%;
      height: 40%;
      display: inline-flex;
      margin-right: 15px;
    }
    .content2 {
      padding: 10px;
      margin: 10px 15px 0 0;
      background: rgba(0, 0, 0, 0.5);
      width: 98.5%;
      height: 57%;
      border-radius: 15px;
    }
  }
}
.confirm {
  width: 100px;
  margin: 0 auto;
}
.select {
  margin-bottom: 20px;
}
.check {
  text-align: right;
  margin-top: 10px;
}
.personHistory {
  margin-right: 20px;
}
.tablePerson {
  width: 100%;
  height: 350px;
  overflow: scroll;
  padding-bottom: 20px;
  //   margin-bottom: 10px;
}
.table-row:hover {
  background: rgb(191, 220, 247);
}
/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 600;
  color: #01143f;
}
/deep/.el-dialog__title::before {
  content: "丨";
}
// el-table 表格 透明样式
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}
/deep/::-webkit-scrollbar {
  display: none;
}
/deep/ .el-table th.el-table__cell > .cell {
  font-size: 18px;
  color: rgba(0, 255, 255, 0.952);
}
/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 16px;
  color: rgb(4, 224, 224);
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}
/deep/ th.el-table__cell.is-leaf {
  border: none;
}
/deep/.el-table::before {
  height: 0px;
}

/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: none;
  background: #01143f;
  color: #0bd9e0;
  font-size: 16px;
  letter-spacing: 1px;
}
</style>