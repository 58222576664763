import request from '@/../utils/request'
const api_name = '/iotechserver/AKeyAlarmController'

export default {
    getPageAKeyAlarm(pageAKeyAlarm) {
        return request({
            url: `${api_name}/pageAKeyAlarmInfo`,
            method: 'post',
            data: pageAKeyAlarm
        })
    },
    handOut(AKeyAlarmPerson) {
        return request({
            url: `${api_name}/handOutRoles`,
            method: 'post',
            data: AKeyAlarmPerson
        })
    },
    dealWith(AKeyAlarmPerson) {
        return request({
            url: `${api_name}/dealWith`,
            method: 'post',
            data: AKeyAlarmPerson
        })
    },
    getAllAkeyAlarmPerson() {
        return request({
            url: `${api_name}/getAllAkeyAlarmPerson`,
            method: 'get',
        })
    },
    getDepartmentById(id) {
        return request({
            url: `${api_name}/getDepartmentById`,
            method: 'post',
            data: {
                ciId: id
            }
        })
    },
    getPersonHistory(pageAKeyAlarm) {
        return request({
            url: `${api_name}/getPersonHistory`,
            method: 'post',
            data: pageAKeyAlarm
        })
    },

    getAkeyAlarmInfoById(id) {
        return request({
            url: `${api_name}/getAkeyAlarmInfoById`,
            method: 'post',
            data: {
                id: id
            }
        })
    }

}